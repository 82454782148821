"use strict";

import Vue from 'vue';
import axios from "axios";
import store from '../store'
import router from '../router'

// Full config:  https://github.com/axios/axios#request-config
axios.defaults.baseURL= process.env.NODE_ENV === 'production' ? 'https://hr01981800200.bigerpbd.com/public/api' : 'http://127.0.0.1:8000/api';
const token = localStorage.getItem('token')
if(token){
  axios.defaults.headers.common['Authorization'] = token
}
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

let config = {
  // baseURL: process.env.baseURL || process.env.apiUrl || ""
  // timeout: 60 * 1000, // Timeout
  // withCredentials: true, // Check cross-site Access-Control
};

const _axios = axios.create(config);

_axios.interceptors.request.use(
  function(config) {
    // Do something before request is sent
    let is_guest = router.currentRoute.meta.hasOwnProperty("guest");
    let token = localStorage.getItem('token');
    if(!is_guest && !token){
        store.commit('user/logout');
        delete axios.defaults.headers.common['Authorization'];
        router.push('/login').catch(()=>{})
    }
    return config;
  },
  function(error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
_axios.interceptors.response.use(
  function(response) {
    // Do something with response data
    return response;
  },
  function(error) {
    // Do something with response error
    if (error) {
      // manage error and expire token
      const originalRequest = error.config;
      if (error.response.status === 401 && !originalRequest._retry) {
          originalRequest._retry = true;
          store.commit('user/logout');
          delete axios.defaults.headers.common['Authorization'];
          router.push('/login').catch(()=>{})
      }
  
      return Promise.reject(error);
    }
  }
);

Plugin.install = function(Vue, options) {
  Vue.axios = _axios;
  window.axios = _axios;
  Object.defineProperties(Vue.prototype, {
    axios: {
      get() {
        return _axios;
      }
    },
    $axios: {
      get() {
        return _axios;
      }
    },
  });
};

Vue.use(Plugin)

export default Plugin;
